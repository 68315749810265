import profilePic from 'img/the-smallphish.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Nav from 'react-bootstrap/Nav';

import './App.css';

function App() {
  return (
    <div className="App">

      <header className="App-header">
        <div>
          <span>
            <Nav.Link href="https://www.linkedin.com/in/smallphish"
                    active>
            <FontAwesomeIcon icon={faLinkedin}
                           size="2x"
                           className="d-inline-block align-top"/>
            </Nav.Link>
          </span>
          <span>
            <Nav.Link href="https://github.com/smallphish">
            <FontAwesomeIcon icon={faGithub}
                             size="2x"
                             className="d-inline-block align-top"/>
            </Nav.Link>
          </span>
        </div>

        <img src={profilePic} className="App-logo" alt="logo" />
        <h1>&lt;SMALLPHISH /&gt;</h1>
        <p>
          If you have a problem... <br/>
          if your Fintech needs help...<br/>
          and you send him an InMail&trade;...<br/> 
          maybe you can hire...
        </p>
        <strong style={{color: 'burlywood'}}>The Smallphish.</strong>
      </header>
    </div>
  );
}

export default App;
